import { Paper, Typography, Box, Button, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { getCurrentGoldProductsArray } from '../../../../store/selectors/main';
import { getProductCode } from '../../../../shared/utils/product-utils';
import { useTranslation } from 'react-i18next';
import { dateFormatted } from '../../../../shared/utils/date-utils';
import { signupSubmitApplication } from '../../../../store/actions/signupActions';
import { useNavigate } from 'react-router-dom';

export const SignupRegisterSubmit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contact, dependent_contacts, patient_plan } = getMyPatientData();

  // Determine membership type
  const numDependents = dependent_contacts.length;
  const membershipType =
    numDependents === 0 ? 'Individual Member' :
      numDependents === 1 ? 'Member and One Dependent' :
        numDependents <= 3 ? 'Member and Up to Three Dependents' :
          'Member and Four or More';

  // Payment interval and rate calculation
  const paymentInterval = patient_plan?.paymentIntervalId;
  const currentGoldProducts = useSelector(getCurrentGoldProductsArray);
  const productCode = getProductCode(numDependents, paymentInterval!);
  const rate = currentGoldProducts.find(x => x.productCode === productCode)?.rate || 0;

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {t('Sign up Review')}
      </Typography>

      <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px' }}>
        <Typography variant="h6" gutterBottom>
          {t('Patient Information')}
        </Typography>
        <Divider style={{ marginBottom: '16px' }} />
        <Typography><strong>{t('First Name')}:</strong> {contact?.firstName}</Typography>
        <Typography><strong>{t('Last Name')}:</strong> {contact?.lastName}</Typography>
        <Typography><strong>{t('Email')}:</strong> {contact?.email}</Typography>
        <Typography><strong>{t('Birth Date')}:</strong> {dateFormatted(contact?.birthDate?.toString())}</Typography>
      </Paper>

      <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px' }}>
        <Typography variant="h6" gutterBottom>
          {t('Membership Details')}
        </Typography>
        <Divider style={{ marginBottom: '16px' }} />
        <Typography><strong>{t('Membership Type')}:</strong> {membershipType}</Typography>
        <Typography >
          <strong>{t('Rate')}:</strong> ${rate} {paymentInterval === 1 ? t('Monthly') : t('Annual')}
        </Typography>
      </Paper>

      {dependent_contacts.length > 0 && (
        <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px' }}>
          <Typography variant="h6" gutterBottom>
            {t('Dependents')}
          </Typography>
          <Divider style={{ marginBottom: '16px' }} />
          {dependent_contacts.map((dependent, index) => (
            <>
              <Typography><strong>{t('First Name')}:</strong> {dependent.firstName}</Typography>
              <Typography><strong>{t('Last Name')}:</strong> {dependent.lastName}</Typography>
              <Typography><strong>{t('Birth Date')}:</strong> {dateFormatted(dependent.birthDate)}</Typography>
              {index !== dependent_contacts.length - 1 ? <hr /> : null}
            </>
          ))}
        </Paper>
      )}

      <Box mt={4} textAlign="center">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(signupSubmitApplication({}, () => {
              navigate("/Signup/Thankyou");
            }));
          }}
        >
          {t('Submit')}
        </Button>
      </Box>
    </>
  );
};