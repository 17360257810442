export enum ACTIONS {
	ERROR = "ERROR",
	SUCCESS = "SUCCESS",
	SHOW_ERROR = "SHOW_ERROR",
	IS_LOADING = 'IS_LOADING',
	NOT_LOADING = 'NOT_LOADING',
	SIGN_IN = 'SIGN_IN',
	LOGOUT = "LOGOUT",
	REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
	PASSWORD_RESET = 'PASSWORD_RESET',
	SET_CLIENT_OPTIONS = 'SET_CLIENT_OPTIONS',

	//signup
	SIGNUP_UPSERT_YOUR_INFO = 'SIGNUP_UPSERT_YOUR_INFO',
	SIGNUP_CREATE_USER = 'SIGNUP_CREATE_USER',
	SIGNUP_UPDATE_FAMILY_MEMBERS = 'SIGNUP_UPDATE_FAMILY_MEMBERS',
	SIGNUP_CREATE_PAYMENT_PROFILE = 'SIGNUP_CREATE_PAYMENT_PROFILE',
	SIGNUP_SUBMIT_APPLICATION = 'SIGNUP_SUBMIT_APPLICATION',
	//auth
	CONFIRM_EMAIL = 'CONFIRM_EMAIL',
	REFRESH_USER_DATA = 'REFRESH_USER_DATA',

	//user
	GET_USER = 'GET_USER',
	GET_USERS = 'GET_USERS',
	UPDATE_MY_USER = 'UPDATE_MY_USER',
	GET_USER_NOTES = 'GET_USER_NOTES',
	CREATE_USER_NOTE = 'CREATE_USER_NOTE',
	UPDATE_USER_NOTE = 'UPDATE_USER_NOTE',
	DELETE_USER_NOTE = 'DELETE_USER_NOTE',
	USER_ACTIVATION = 'USER_ACTIVATION',
	ADD_REMOVE_ROLE = 'ADD_REMOVE_ROLE',
	GET_ROLES = 'GET_ROLES',
	GET_MY_USER_DATA = 'GET_MY_USER_DATA',

	//REPORTING
	DOWNLOAD_USERS_REPORT = 'DOWNLOAD_USERS_REPORT',
	GET_USER_ROLES = 'GET_USER_ROLES',
	GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA',
	GET_DASHBOARD_DETAIL_DATA = 'GET_DASHBOARD_DETAIL_DATA',
	SET_TIMEZONE = 'SET_TIMEZONE',

	//RESOURCES
	CREATE_UPLOAD_RESOURCE = 'CREATE_UPLOAD_RESOURCE',
	UPLOAD_RESOURCE_FILE = 'UPLOAD_RESOURCE_FILE',
	GET_UPLOAD_URLS = 'GET_UPLOAD_URLS',
	CONVERT_RESOURCES = "CONVERT_RESOURCES",
	GET_MY_PROFILE = "GET_MY_PROFILE",

	//AWS
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD = 'INIT_PRESIGNED_URL_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD = 'INIT_PRESIGNED_URL_SIMPLE_UPLOAD',
	COMPLETE_MULTIPART_UPLOAD = 'COMPLETE_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS",
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS",
	CREATE_UPLOAD_RESOURCE_SUCCESS = "CREATE_UPLOAD_RESOURCE_SUCCESS",
	GET_PRESIGNED_UPLOAD_URLS_SUCCESS = 'GET_PRESIGNED_UPLOAD_URLS_SUCCESS',

	//SOCKET
	INIT_SOCKET = 'INIT_SOCKET',
	DISCONNECT_SOCKET = 'DISCONNECT_SOCKET',
	SYNC_DATA_FROM_SOCKET = 'SYNC_DATA_FROM_SOCKET',
	SOCKET_MESSAGE_ERROR = 'SOCKET_MESSAGE_ERROR',

	//GLOBAL SETTINGS
	GET_GLOBAL_SETTINGS = 'GET_GLOBAL_SETTINGS',
	UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS',

	//LOGS
	QUERY_LOGS = 'QUERY_LOGS',

	GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS',
	SIGNUP_SET_LOCATION = 'SIGNUP_SET_LOCATION',

	UPSERT_SIGNNOW_DOCUMENT = 'UPSERT_SIGNNOW_DOCUMENT',

	
}

