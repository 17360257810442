import { ElementRef, forwardRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

export type DateFieldProps = { name: string; value: Date };

export const DateField = forwardRef<ElementRef<typeof DatePicker>, DateFieldProps>(({ name, value, ...props }, ref) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Ensure that the value passed to DatePicker is a moment object
  const currentValue = field.value ? moment(field.value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...props}
        ref={ref}
        value={currentValue} // Pass moment object or null
        onChange={(val) => {
          // Convert to standard Date object before passing to Formik
          setFieldValue(name, val ? val.toDate() : null);
        }}
        slotProps={{ textField: { fullWidth: true } }}
      />
    </LocalizationProvider>
  );
});

DateField.displayName = 'DateField';
