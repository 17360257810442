import React, { useEffect, useRef, useState } from "react";
import { Tab, Container } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CenteredContainer from "../../../components/layout/CenteredContainer";
import { SignupLogin } from "./SignupLogin";
import { SignupRegister } from "./SignupRegister";
import { useNavigate } from "react-router-dom";
import { getMyPatientData } from "../../../store/hooks/getMyPatientData";

type Tabs = "register" | "login";

export const Signup = () => {
  const [value, setValue] = useState<Tabs>("register");
  const [height, setHeight] = useState<number>(0);
  const container = useRef<HTMLDivElement>(null);

  const handleChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setValue(newValue);
    if (container.current && container.current.clientHeight > height) {
      setHeight(container.current.clientHeight);
    }
  };

  const myPatientData = getMyPatientData();
  const navigate = useNavigate();


    useEffect(() => {
    if (myPatientData.my_user?.email && !myPatientData.signup_complete) {
      navigate('/signup/register')
    };
  }, [myPatientData.signup_complete, myPatientData.my_user?.email]);

  return (
    <CenteredContainer>
      <Container
        ref={container}
        maxWidth="md"
        style={{
          backgroundColor: "white",
          height: height === 0 ? "auto" : height,
        }}
      >
        <CenteredContainer>
          <TabContext value={value}>
            <TabList onChange={handleChange} centered>
              <Tab label="Register" value="register" />
              <Tab label="Login" value="login" />
            </TabList>
            <TabPanel value="register">
              <SignupRegister />
            </TabPanel>
            <TabPanel value="login">
              <SignupLogin />
            </TabPanel>
          </TabContext>
        </CenteredContainer>
      </Container>
    </CenteredContainer>
  );
};

export default Signup;
