export const getRateForProduct = (productCode: string, currentGoldProducts: any[]) => {
    const product = currentGoldProducts.find((x) => x.productCode === productCode);
    if (product) {
        return product.rate;
    }
    return 0;
};

export const productMemberships = (currentGoldProducts: any[]) => {

    return [
        {
            name: 'Individual Member',
            monthly: getRateForProduct('2022_06_01_g_0d_m', currentGoldProducts),
            yearly: getRateForProduct('2022_06_01_g_0d_a', currentGoldProducts),
            maxDependents: 0,
        },
        {
            name: 'Member and One Dependent',
            monthly: getRateForProduct('2022_06_01_g_1d_m', currentGoldProducts),
            yearly: getRateForProduct('2022_06_01_g_1d_a', currentGoldProducts),
            maxDependents: 1,
        },
        {
            name: 'Member and Up to Three Dependents',
            monthly: getRateForProduct('2022_06_01_g_3d_m', currentGoldProducts),
            yearly: getRateForProduct('2022_06_01_g_3d_a', currentGoldProducts),
            maxDependents: 3,
        },
        {
            name: 'Member and Four or More',
            monthly: getRateForProduct('2022_06_01_g_4d_m', currentGoldProducts),
            yearly: getRateForProduct('2022_06_01_g_4d_a', currentGoldProducts),
            maxDependents: 99,
        },
    ];
}

export const getProductCode = (numDependents: number, paymentInterval: number) => {
    // Determine the correct number of dependents to use in the product code
    let maxDependents;

    if (numDependents === 0) {
        maxDependents = 0;
    } else if (numDependents === 1) {
        maxDependents = 1;
    } else if (numDependents <= 3) {
        maxDependents = 3;
    } else {
        maxDependents = 4; // For "Member and Four or More"
    }

    return `2022_06_01_g_${maxDependents}d_${paymentInterval === 1 ? 'm' : 'a'}`;
}
