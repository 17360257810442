import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { forwardRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { PAYMENT_INTERVAL } from '../../../../shared/constants/model.constants';
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { Checkbox } from '../../../../components/form/Checkbox';
import { Month } from '../../../../components/form/Month';
import { Year } from '../../../../components/form/Year';
import { Select } from '../../../../components/form/Select';
import { States } from '../../../../components/form/States';
import { signupCreatePaymentProfile } from '../../../../store/actions/signupActions';

const creditCardSchema = Yup.string().test('is-valid-credit-card', 'Invalid credit card number', (value) => {
  // Remove any non-digit characters
  const sanitizedValue = value?.replace(/\D/g, '');

  if (!sanitizedValue) {
    return false;
  }
  // Perform Luhn algorithm check
  let sum = 0;
  let shouldDouble = false;
  for (let i = sanitizedValue.length - 1; i >= 0; i--) {
    let digit = parseInt(sanitizedValue.charAt(i), 10);
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
});

const validationSchema = Yup.object({
  useCCOnFile: Yup.boolean(),
  paymentInterval: Yup.number().required('Payment interval is required'),
  cardNumber: creditCardSchema,
  expirationMonth: Yup.string().required('Expiration month is required'),
  expirationYear: Yup.string().required('Expiration year is required'),
  cvv: Yup.string()
    .required('CVV is required')
    .matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits'),
  preferredChargeDay: Yup.number().min(1).max(31),
  sameAddressAsPersonal: Yup.boolean(),
  streetAddress1: Yup.string().when('sameAddressAsPersonal', {
    is: false,
    then: (schema) => schema.required('Street address is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  streetAddress2: Yup.string(),
  firstName: Yup.string().when('sameAddressAsPersonal', {
    is: false,
    then: (schema) => schema.required('First name is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  lastName: Yup.string().when('sameAddressAsPersonal', {
    is: false,
    then: (schema) => schema.required('Last name is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  city: Yup.string().when('sameAddressAsPersonal', {
    is: false,
    then: (schema) => schema.required('City is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  state: Yup.string().when('sameAddressAsPersonal', {
    is: false,
    then: (schema) => schema.required('State is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  code: Yup.string().when('sameAddressAsPersonal', {
    is: false,
    then: (schema) => schema.required('ZIP is required').matches(/^[0-9]+$/, 'Must be only digits'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export type SignupRegisterPaymentInfoType = Yup.InferType<typeof validationSchema>;

export type SignupRegisterPaymentInfoProps = { onSuccess: () => void };

export const SignupRegisterPaymentInfo = forwardRef<FormikProps<SignupRegisterPaymentInfoType>, SignupRegisterPaymentInfoProps>(({ onSuccess }, ref) => {
  const myPatientData = getMyPatientData();

  console.log("myPatientData in payment info", myPatientData)

  const dispatch = useDispatch();
  const hasExistingProfile = useMemo(() => !!myPatientData?.payment_data?.anetccProfileId, [myPatientData?.payment_data?.anetccProfileId]);

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        useCCOnFile: hasExistingProfile,
        paymentInterval: myPatientData?.patient_plan?.paymentIntervalId as PAYMENT_INTERVAL,
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvv: '',
        preferredChargeDay: 0,
        sameAddressAsPersonal: false,
        firstName: '',
        lastName: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '0',
        code: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(
          signupCreatePaymentProfile(
            {
              ...values,
              ...(values.useCCOnFile ? { expirationMonth: 1, expirationYear: 1, preferredChargeDay: 1, state: 1 } : {}),
            },
            () => {
              onSuccess?.();
            },
            () => {},
            'Payment info updated',
            true,
            true,
          ),
        );
      }}
      enableReinitialize={true}
    >
      {({ setFieldValue, values, touched, errors }) => {
        return (
          <Form>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap', // This will allow the items to wrap on smaller screens
                marginBottom: '0px',
                gap: 2, // This creates a gap between items
                mt: 2,
              }}
            >
              <Card
                elevation={1}
                sx={{
                  ...(values.paymentInterval === PAYMENT_INTERVAL.MONTHLY && {
                    backgroundColor: '#7b287c',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  }),
                }}
                onClick={() => {
                  setFieldValue('paymentInterval', PAYMENT_INTERVAL.MONTHLY);
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="center" sx={{ width: '100%', cursor: 'pointer' }}>
                    Monthly by Credit / Debit Card
                  </Typography>
                </CardContent>
              </Card>

              <Card
                elevation={1}
                sx={{
                  ...(values.paymentInterval === PAYMENT_INTERVAL.ANNUAL && {
                    backgroundColor: '#7b287c',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  }),
                }}
                onClick={() => {
                  setFieldValue('paymentInterval', PAYMENT_INTERVAL.ANNUAL);
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="center" sx={{ width: '100%', cursor: 'pointer' }}>
                    Annually by Credit / Debit Card
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <ErrorMessage name={`paymentInterval`}>{(msg) => <Typography color="error">{msg}</Typography>}</ErrorMessage>

            {hasExistingProfile && (
              <Grid item xs={12}>
                <Field as={Checkbox} name="useCCOnFile" label="Use Existing CC Profile" variant="outlined" />
              </Grid>
            )}

            <Grid container spacing={2} sx={{ marginTop: '12px' }}>
              {/* Card Number */}

              {(!values.useCCOnFile) && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="cardNumber"
                      label="Card Number"
                      variant="outlined"
                      error={touched.cardNumber && Boolean(errors.cardNumber)}
                      helperText={<ErrorMessage name="cardNumber" />}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field
                      as={Month}
                      name="expirationMonth"
                      label="Expiration Month"
                      variant="outlined"
                      error={touched.expirationMonth && Boolean(errors.expirationMonth)}
                      helperText={<ErrorMessage name="expirationMonth" />}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field
                      as={Year}
                      name="expirationYear"
                      label="Expiration Year"
                      variant="outlined"
                      error={touched.expirationYear && Boolean(errors.expirationYear)}
                      helperText={<ErrorMessage name="expirationYear" />}
                      fullWidth
                    />
                  </Grid>

                  {/* CVV Code */}
                  <Grid item sm={3} xs={12}>
                    <Field
                      as={TextField}
                      name="cvv"
                      label="CVV Code"
                      variant="outlined"
                      error={touched.cvv && Boolean(errors.cvv)}
                      helperText={<ErrorMessage name="cvv" />}
                      fullWidth
                    />
                  </Grid>

                  {/* Preferred Charge Date */}
                  <Grid item xs={12} sm={3}>
                    <Field
                      as={Select}
                      name="preferredChargeDay"
                      label="Preferred Charge Date"
                      variant="outlined"
                      error={touched.preferredChargeDay && Boolean(errors.preferredChargeDay)}
                      helperText={<ErrorMessage name="preferredChargeDay" />}
                      options={Array.from({ length: 28 }, (_, i) => i + 1).map((i) => ({ label: i, value: i }))}
                      fullWidth
                    />
                  </Grid>

                  {/* Same Address Checkbox */}
                  <Grid item xs={12}>
                    <Field as={Checkbox} name="sameAddressAsPersonal" label="Same address as personal information?" variant="outlined" />
                  </Grid>

                  {/* Address Fields */}
                  {!values.sameAddressAsPersonal && (
                    <>
                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="firstName"
                          label="First Name"
                          variant="outlined"
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={<ErrorMessage name="firstName" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="lastName"
                          label="Last Name"
                          variant="outlined"
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={<ErrorMessage name="lastName" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="streetAddress1"
                          label="Street Address (Line 1)"
                          variant="outlined"
                          error={touched.streetAddress1 && Boolean(errors.streetAddress1)}
                          helperText={<ErrorMessage name="streetAddress1" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="streetAddress2"
                          label="Street Address (Line 2)"
                          variant="outlined"
                          error={touched.streetAddress2 && Boolean(errors.streetAddress2)}
                          helperText={<ErrorMessage name="streetAddress2" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={4} xs={6}>
                        <Field
                          as={TextField}
                          name="city"
                          label="City"
                          variant="outlined"
                          error={touched.city && Boolean(errors.city)}
                          helperText={<ErrorMessage name="city" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={4} xs={6}>
                        <Field as={States} name={'state'} label={'State'} error={touched.state && Boolean(errors.state)} helperText={<ErrorMessage name="state" />} />
                      </Grid>

                      {/* Zip Code */}
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          name="code"
                          label="Zip"
                          fullWidth
                          variant="outlined"
                          error={touched.code && Boolean(errors.code)}
                          helperText={<ErrorMessage name="code" />}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: '#007f7e' }}>
                  Review
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});
