import React, { useEffect } from 'react';
import './lang/i18n';
import { getI18n } from 'react-i18next';
import { useSelector } from './store';

export const TranslatorProvider = ({ children }: { children: React.ReactNode }) => {
    
    const language = useSelector((state) => state.main.my_user?.language || 'en');

    useEffect(() => {
        if (language){
            const i18n = getI18n();
            i18n.changeLanguage(language);
        }
    }, [language])

    return (
        <>
            {children}
        </>
    );
};