
import { isString } from 'lodash';

const isUpperCase = (str: any) => /^[A-Z]*$/.test(str)

export const capFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const safeCurrency = (num: any) => {
	try {
		return usdFormatter.format(num);
	}
	catch {
		return '';
	}

}

export function getCookie(name: string) {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(name + '='));
    return cookieValue ? cookieValue.split('=')[1] : null;
}

export function deleteCookie(name: string) {
    document.cookie = name + '=; Max-Age=0; path=/;';
}

export const safeSum = (a: number, b: number) => {
	let aa = Number(a);
	let bb = Number(b);
	if (isNaN(a)) {
		aa = 0
	}
	if (isNaN(b)) {
		bb = 0
	}
	return aa + bb;
}

export const safeBool = (
	input: string | boolean,
	defaultValue = false,
): boolean => {
	try {
		let newInput = input;
		if (typeof input === 'string') {
			newInput = input.toLowerCase();
		}
		if (newInput === 'true' || newInput === true || newInput === "yes" || newInput === "accepted") {
			return true;
		}
		if (newInput === 'false' || newInput === false || newInput === "no" || newInput === 'declined') {
			return false;
		}
		return defaultValue;
	} catch (e) {
		return defaultValue;
	}
};


export const hasNumberLetter6Length = (str: any) => {
	let hasNumber = false;
	let hasUpper = false;

	for (let i = 0; i < str.length; i++) {
		const char = str.charAt(i)
		const upperCase = isUpperCase(char)
		const isNumber = !isNaN(char)
		if (upperCase) {
			hasUpper = true;
		}
		if (isNumber) {
			hasNumber = true;
		}
	}
	if (str.length >= 6 && hasUpper && hasNumber) {
		return true;
	}
	return false;
}

export const rollChance = (chance: number) => {
    if (chance < 0 || chance > 100) {
        throw new Error("Chance must be between 0 and 100");
    }

    const randomValue = Math.random() * 100;
    return randomValue < chance;
}

export const shuffleArray = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
}

export const removeKeyGlobally = (text: string, key: string): string => {
    // Escape special characters for regular expression
    const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const escapedKey = escapeRegExp(key);
    const regex = new RegExp(escapedKey, 'g'); // 'g' for global replacement

    return text.replace(regex, '');
};

export const extractTextBetween = (str: string, start: string, end: string) => {
	// Escape special characters for use in a regular expression
	const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

	const startEscaped = escapeRegExp(start);
	const endEscaped = escapeRegExp(end);

	const regex = new RegExp(`${startEscaped}(.*?)${endEscaped}`);
	const match = str.match(regex);
	return match ? match[1] : null;
}

export const removeEnclosedText = (text: string, startDelimiter: string, endDelimiter: string) => {
	// Escape special characters for regular expression
	const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

	const escapedStartDelimiter = escapeRegExp(startDelimiter);
	const escapedEndDelimiter = escapeRegExp(endDelimiter);

	const regex = new RegExp(`${escapedStartDelimiter}.*?${escapedEndDelimiter}`, 'gs');
	return text.replace(regex, '');
}

export const isEmptyString = (text: any) => {
	return text.trim() === '';
}

export const makeSearchObject = (obj: any, include_fields = [] as any[], include_dates = [] as any[]) => {
	const returnVals = [] as any[];
	for (const [key, value] of Object.entries(obj)) {
		switch (typeof value) {
			case 'string':
			case 'number':
				if (include_fields.length === 0 || include_fields.includes(key)) {
					returnVals.push(value)
				}
				break;
			case 'object':
				if (include_dates.includes(key)) {
					if (value instanceof Date) {
						const year = value.getFullYear();
						const month = (value.getMonth() + 1).toString().padStart(2, '0');
						const day = value.getDate().toString().padStart(2, '0');

						const hyphenFormat = `${year}-${month}-${day}`;
						const slashFormat = `${year}/${month}/${day}`;
						const hyphenReverseFormat = `${month}-${day}-${year}`;
						const slashReverseFormat = `${month}/${day}/${year}`;

						returnVals.push(hyphenFormat)
						returnVals.push(slashFormat)
						returnVals.push(hyphenReverseFormat)
						returnVals.push(slashReverseFormat)
					}
				}
				break;
			default:
				break;
		}
	}
	return returnVals.join(' ');
}

export const roundNumberFixed2 = (num: any): number => {
	try {
		return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
	}
	catch {
		return 0
	}
}


export const boolToString = (val: any, trueVal: string, falseVal: string) => {
	let boolval = false;
	if (typeof val === 'boolean') {
		boolval = val;
	} else {
		if (typeof val === 'string') {
			if (val.toLowerCase() === 'true') {
				boolval = true;
			}
		}
	}
	if (boolval === true) {
		return trueVal;
	}
	return falseVal;
};

export const usdFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const getUrlParam = (param: string) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get(param);
};

export function GetFriendlyError(error: any): string {
	try {
		if (isString(error)) {
			return error as string;
		}
	}
	catch (e) {
		console.log(e)
	}
	return 'Error: Unknown Error'
}

export function enumToArrayOfStrings<T>(enumObject: T): string[] {
	return Object.keys(enumObject as any)
		.filter(key => isNaN(Number(key)))
		.map(key => key.toString());
}

export function reduceObjectToKeys<T extends object>(obj: T, keysToKeep: Array<keyof T>): Partial<T> {
    return keysToKeep.reduce((newObj, key) => {
        if (obj.hasOwnProperty(key)) {
            (newObj as any)[key] = obj[key];
        }
        return newObj;
    }, {} as Partial<T>);
}

export function logDateWithHundredthOfSecond() {
    let now = new Date();
    let hours = String(now.getHours()).padStart(2, '0');
    let minutes = String(now.getMinutes()).padStart(2, '0');
    let seconds = String(now.getSeconds()).padStart(2, '0');
    let milliseconds = String(now.getMilliseconds()).padStart(3, '0') as any;
    let hundredthOfSecond = Math.floor(milliseconds / 10);

    return `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()} ${hours}:${minutes}:${seconds}.${hundredthOfSecond}`;
}

export function extractIdFromString(str: string) {
	// Regular expression to find 'ID=' followed by any number of digits
	const regex = /ID=(\d+)/;

	// Using the regex to find the match in the string
	const match = str.match(regex);

	// Extracting the digits
	let id = null as any;
	if (match) {
		id = match[1]; // This will be '8' in the example
	}
	return id
}