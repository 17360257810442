import { IContact } from '../../shared/interfaces/model/contact.interface';
import { IPatient } from '../../shared/interfaces/model/patient.interface';
import { IAddress } from '../../shared/interfaces/model/address.interface';
import { IPaymentData } from '../../shared/interfaces/model/payment-data.interface';
import { useSelector } from 'react-redux';
import { IRootState } from '../../interfaces/store';
import { getAddressesArray, getContactsArray, getDependentPatientPlansArray, getDependentsArray, getPatientPlansArray, getPatientsArray, getPaymentDatasArray } from '../selectors/main';
import { IPatientPlan } from '../../shared/interfaces/model/patient-plan.interface';
import { orderBy } from 'lodash';
import { IDependentPatientPlan } from '../../shared/interfaces/model/depenent-patient-plan.interface';
import { IDependent } from '../../shared/interfaces/model/dependent.interface';

export interface IGetMyPatientData {
    my_user: IAspNetUser | undefined;
    contact: IContact | undefined;
    patient: IPatient | undefined;
    address: IAddress | undefined;
    patient_plan: IPatientPlan | undefined;
    payment_data: IPaymentData | undefined;
    dependents: IDependent[];
    dependent_contacts: IContact[];
    dependent_patient_plans: IDependentPatientPlan[];
    info_complete: boolean;
    location_complete: boolean;
    plan_type_complete: boolean;
    payment_data_complete: boolean;
    signup_complete: boolean;
}

// Custom hook to get users and their accessors
export function getMyPatientData(): IGetMyPatientData {

    const myUser = useSelector(
        (state: IRootState) => state.main.my_user
    );

    const contactsArr = useSelector(getContactsArray);
    const addressesArr = useSelector(getAddressesArray);
    const patientsArr = useSelector(getPatientsArray);
    const paymentDatasArr = useSelector(getPaymentDatasArray);
    const patientPlansArr = useSelector(getPatientPlansArray)
    const dependentPatientPlansArr = useSelector(getDependentPatientPlansArray);

    let myPatient = undefined as IPatient | undefined;
    let myPaymentData = undefined as IPaymentData | undefined;
    const myContact = contactsArr.find((contact: IContact) => contact.id === myUser.contactId);

    let myAddress = undefined as IAddress | undefined;
    if (myContact) {
        myPatient = patientsArr.find((patient: IPatient) => patient.contactId === myContact.id);
        myAddress = addressesArr.find((address: IAddress) => address.id === myPatient?.addressId);
    }

    let patientPlan = undefined as IPatientPlan | undefined;
    if (myPatient) {
        myPaymentData = paymentDatasArr.find((paymentData: IPaymentData) => paymentData.id === myPatient.paymentDataId);
        var patientPlansFiltered = patientPlansArr.filter((patientPlan: IPatientPlan) => patientPlan.patientId === myPatient.id);
        if (patientPlansFiltered.length > 0) {
            patientPlansFiltered = orderBy(patientPlansFiltered, ['createdAt'], ['desc']);
            patientPlan = patientPlansFiltered[0];
        }
    }

    let plan_type_complete = false;
    if (patientPlan) {
        if (patientPlan.signupSource === "self-plan-type" || patientPlan.signupSource === "self-payment-profile"){
            plan_type_complete = true;
        }
    }

    let payment_data_complete = false;
    //console.log("IN SELECTOR", myPaymentData)
    if (myPaymentData?.anetProfileId && myPaymentData?.anetccProfileId) {
        payment_data_complete = true;
    }

    const dependentsArr = useSelector(getDependentsArray);

    let dependentPatientPlans = [] as IDependentPatientPlan[];
    let dependents = [] as IDependent[]

    let depCount = 0;
    if (patientPlan?.id) {

        dependentPatientPlans = dependentPatientPlansArr.filter((plan: IDependentPatientPlan) => plan.patientPlanId === patientPlan?.id);
        if (dependentPatientPlans) {
            depCount = dependentPatientPlans.length;
        }
    }

    let depContacts = [] as IContact[];
    if (dependentPatientPlans.length) {
        dependents = dependentsArr.filter((dep: any) => dependentPatientPlans.map((plan: any) => plan.dependentId).includes(dep.id));
        depContacts = contactsArr.filter((contact: any) => dependents.map((dep: any) => dep.contactId).includes(contact.id));
    }

    let info_complete = false;
    let location_complete = false;
    if (myUser && myContact && myPatient && myAddress) {
        info_complete = true;
        // location id 1 is default HQ and has not been selected
        if (myPatient.locationId && myPatient.locationId > 1) {
            location_complete = true;
        }
    }

    let signnow_complete = false;

    const signup_complete = info_complete && location_complete && plan_type_complete && payment_data_complete && signnow_complete;

    return {
        my_user: myUser,
        contact: myContact,
        dependent_contacts: depContacts,
        patient: myPatient,
        address: myAddress,
        payment_data: myPaymentData,
        patient_plan: patientPlan,
        dependents,
        dependent_patient_plans: dependentPatientPlans,
        info_complete,
        location_complete,
        plan_type_complete,
        payment_data_complete,
        signup_complete,
    };
}



