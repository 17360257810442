import React, { useState } from 'react';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useDispatch } from 'react-redux';
import { logout, updateMyUser } from '../../store/actions/userActions';
import { AccountCircle } from "@mui/icons-material";
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import { deleteCookie } from '../../shared/utils/gen-utils';
import { doFullLogout, DOT_NET_COOKIE_NAME } from '../../shared/constants/api-constants';

const LanguageMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (lang: string) => {
    setAnchorEl(null);
    dispatch(updateMyUser({
      language: lang
    }))
  };

  return (
    <div>
      <IconButton
        aria-label="language"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { handleSelect('en') }}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          English
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('es') }}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          Spanish
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('it') }}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          Italian
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('pt') }}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          Portuguese
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('ja') }}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          Japanese
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {

          dispatch(logout({}, () => {
            doFullLogout()
          }, () => {console.log("logouit fail")}, "Logout Success", true, true))

        }}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageMenu;