import { forwardRef, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, Card, CardContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { Delete } from '@mui/icons-material';
import { get } from 'lodash';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { useSelector } from '../../../../store';
import { getCurrentGoldProductsArray } from '../../../../store/selectors/main';
import { Gender } from '../../../../components/form/Gender';
import { Relationship } from '../../../../components/form/Relationship';
import { Location } from '../../../../components/form/Location';
import { useDispatch } from 'react-redux';
import { signupUpdateFamilyMembers } from '../../../../store/actions/signupActions';
import { DateField } from '../../../../components/form/DateField';
import { getRateForProduct, productMemberships } from '../../../../shared/utils/product-utils';

const dependendSchema = Yup.object({
  dependentId: Yup.number().optional(),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  birthDate: Yup.date().required('Birth date is required'),
  gender: Yup.number().required('Gender is required'),
  relationshipId: Yup.number().required('Relationship is required'),
  locationId: Yup.number().required('Location is required'),
});
export type DependendType = Yup.InferType<typeof dependendSchema>;

const validationSchema = Yup.object({
  dependentRows: Yup.array().of(dependendSchema),
});
export type SignupRegisterPlanTypeFormType = Yup.InferType<typeof validationSchema>;

export type SignupRegisterPlanTypeProps = { onSuccess: () => void };

enum PLAN_TYPE_GENERAL {
  INDIVIDUAL = 'individual',
  FAMILY = 'family',
}

const highlightedStyle = {
  backgroundColor: '#7b287c',
  '& .MuiTypography-root': {
    color: '#ffffff',
  },
};

const coreCenteredCardContentStyle = {
  cursor: 'pointer',
  height: '100%', // Set a specific height or keep it responsive based on content
  width: 200,
  // Set a specific height or keep it responsive based on content
};

export const SignupRegisterPlanType = forwardRef<FormikProps<SignupRegisterPlanTypeFormType>, SignupRegisterPlanTypeProps>(({ onSuccess }, ref) => {
  const dispatch = useDispatch();
  const myPatientData = getMyPatientData();
  const [planType, setPlayType] = useState(myPatientData?.dependents?.length > 0 ? PLAN_TYPE_GENERAL.FAMILY : PLAN_TYPE_GENERAL.INDIVIDUAL);
  const currentGoldProducts = useSelector(getCurrentGoldProductsArray);
  const memberships = productMemberships(currentGoldProducts);
  
  const initialValues = useMemo(
    () =>
      myPatientData.dependents.map((dependent): DependendType => {
        const depContact = myPatientData.dependent_contacts.find((contact) => contact.id === dependent.contactId);
        return {
          dependentId: dependent.id ?? 0,
          firstName: depContact?.firstName ?? '',
          lastName: depContact?.lastName ?? '',
          relationshipId: dependent.relationshipId ?? 0,
          birthDate: new Date(depContact?.birthDate || '2024-01-01'),
          gender: depContact?.gender ?? 0,
          locationId: dependent?.locationId ?? 0,
        };
      }),
    [myPatientData],
  );





  return (
    <Formik
      innerRef={ref}
      initialValues={{ dependentRows: initialValues ?? [] }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(
          signupUpdateFamilyMembers(
            { SignupUpdateFamilyMembersDTORows: values?.dependentRows?.map((depRow) => ({ ...depRow, dependentId: depRow.dependentId || 0 })) },
            () => {
              onSuccess?.();
            },
            () => {},
            'Plan Changes Saved',
            true,
            true,
          ),
        );
      }}
      enableReinitialize={true}
    >
      {({ values, touched, errors }) => {
        return (
          <Form style={{ marginTop: '32px' }}>
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <Grid item xs={12} lg={9} sx={{ maxWidth: '75%', width: '100%' }}>
                <TableContainer elevation={0} component={Paper}>
                  <Table aria-label="membership pricing table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Individuals</TableCell>
                        <TableCell align="center">Monthly Membership*</TableCell>
                        <TableCell align="center">Yearly Membership</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {memberships.map((row: any, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="center">
                            ${row.monthly.toFixed(2)} (1st month charge is ${row.monthly.toFixed(2) * 2}**)
                          </TableCell>
                          <TableCell align="center">${row.yearly.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    sx={{ mt: 2 }}
                    style={{
                      margin: 16,
                      padding: 16,
                      border: '1px solid red',
                      borderRadius: 8,
                    }}
                  >
                    * The total cost of the monthly and annual options are equivalent over a year period. The initial charge on the monthly option includes the first and last
                    month.
                  </Typography>
                </TableContainer>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap', // This will allow the items to wrap on smaller screens
                gap: 2, // This creates a gap between items
                my: 2,
              }}
            >
              <Card
                onClick={() => {
                  setPlayType(PLAN_TYPE_GENERAL.INDIVIDUAL);
                }}
                elevation={0}
                sx={{
                  ...(planType === PLAN_TYPE_GENERAL.INDIVIDUAL && highlightedStyle),
                }}
              >
                <CardContent sx={coreCenteredCardContentStyle}>
                  <Typography variant="h6" align="center" sx={{ width: '100%' }}>
                    Individual
                  </Typography>
                </CardContent>
              </Card>

              <Card
                onClick={() => {
                  setPlayType(PLAN_TYPE_GENERAL.FAMILY);
                }}
                elevation={0}
                sx={{
                  ...(planType === PLAN_TYPE_GENERAL.FAMILY && highlightedStyle),
                }}
              >
                <CardContent sx={coreCenteredCardContentStyle}>
                  <Typography variant="h6" align="center" sx={{ width: '100%' }}>
                    Family
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <FieldArray name="dependentRows">
              {({ remove, push }) => (
                <Grid container spacing={2} style={{ display: planType === PLAN_TYPE_GENERAL.INDIVIDUAL ? 'none' : 'inherit' }}>
                  {values.dependentRows?.map((_, index) => (
                    <Grid item xs={12} key={index}>
                      <Card elevation={0} sx={{ padding: '8px', backgroundColor: '#f9f9f9', border: '2px solid #ebebeb' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={TextField}
                              name={`dependentRows[${index}].firstName`}
                              label="First Name"
                              variant="outlined"
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].firstName`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].firstName`} />}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={TextField}
                              name={`dependentRows[${index}].lastName`}
                              label="Last Name"
                              variant="outlined"
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].lastName`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].lastName`} />}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={DateField}
                              name={`dependentRows[${index}].birthDate`}
                              label="Birth Date"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].birthDate`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].birthDate`} />}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={Gender}
                              name={`dependentRows[${index}].gender`}
                              label="Gender"
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].gender`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].gender`} />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={Relationship}
                              name={`dependentRows[${index}].relationshipId`}
                              label="Relationship"
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].relationshipId`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].relationshipId`} />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Field
                              as={Location}
                              name={`dependentRows[${index}].locationId`}
                              label="Location"
                              error={touched.dependentRows && Boolean(get(errors, `dependentRows[${index}].locationId`))}
                              helperText={<ErrorMessage name={`dependentRows[${index}].locationId`} />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <IconButton onClick={() => remove(index)} aria-label="delete">
                              <Delete />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      variant={'outlined'}
                      type="button"
                      onClick={() => push({ firstName: '', lastName: '', birthDate: '', gender: '', relationshipId: '', locationId: myPatientData.patient?.locationId || '' })}
                    >
                      Add Dependent
                    </Button>
                  </Grid>
                </Grid>
              )}
            </FieldArray>
            <Button type="submit" style={{ marginTop: '16px' }} variant="contained">
              Enter your Payment Info
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
});
