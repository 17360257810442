import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { signIn, signupCreateUser } from "../../../store/actions/signupActions";
import { Box, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getMyUserData } from "../../../store/actions/userActions";

export const SignupRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match"
    ),
  });

  const register = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    dispatch(
      signupCreateUser(
        {
          UserName: email,
          Password: password,
        },
        () => {
          dispatch(
            signIn({ email, password }, () => {
              dispatch(
                getMyUserData({}, () => {
                  navigate("/");
                })
              );
            })
          );
        },
        () => {},
        "Registration Success",
        true,
        true
      )
    );
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        register(values);
        actions.setSubmitting(false);
      }}
    >
      {({ handleChange, handleBlur, values, touched, errors }) => (
        <Form>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Field
              sx={{ width: "300px"}}
              as={TextField}
              name="email"
              label="Email"
              variant="outlined"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email ? errors.email : ""}
            />
            <Field
              sx={{ width: "300px" }}
              as={TextField}
              type="password"
              name="password"
              label="Password"
              variant="outlined"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={
                touched.password && errors.password ? errors.password : ""
              }
            />
            <Field
              sx={{ width: "300px" }}
              as={TextField}
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              variant="outlined"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : ""
              }
            />
            <Button
              variant={"outlined"}
              type="submit"
              style={{ padding: 10, marginTop: 20 }}
            >
              Register
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
