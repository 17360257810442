/* eslint-disable no-param-reassign */
import _, { isObject } from "lodash";
import { IReduceAction } from "../../interfaces/store";
import { IMainState } from "../../interfaces/store/main";
import { autoReduce } from "./autoReduce";
import { normalize } from "normalizr";
import { roleSchema, userRoleSchema, aspNetUserSchema } from "../sagas/schema";
import { ACTIONS } from "../../enums/actions";
import { IResource } from "../../shared/interfaces/model/resource.interfaces";
import { IGlobalSettings } from "../../shared/interfaces/global-settings.interface";
import { ITheme, THEME_NAME, getThemeColors } from "../../shared/constants/layout-constants";
import { IAddress } from "../../shared/interfaces/model/address.interface";
import { IPatient } from "../../shared/interfaces/model/patient.interface";
import { IPaymentData } from "../../shared/interfaces/model/payment-data.interface";
import { IContact } from "../../shared/interfaces/model/contact.interface";
import { ILocation } from "../../shared/interfaces/model/location.interface";
import { IState } from "../../shared/interfaces/model/state.interface";
import { IPatientPlan } from "../../shared/interfaces/model/patient-plan.interface";
import { IDependentPatientPlan } from "../../shared/interfaces/model/depenent-patient-plan.interface";
import { IDependent } from "../../shared/interfaces/model/dependent.interface";
import { IRelationship } from "../../shared/interfaces/model/relationship.interface";
import { IProduct } from "../../shared/interfaces/model/product.interface";

let setUser = {} as any;
let setUserRoles = {} as any;
let setRoles = {} as any;
let setGlobalSettings = {} as any;
let existingUser = localStorage.getItem("my_user");
let existingRoles = localStorage.getItem("roles");
let existingUserRoles = localStorage.getItem("user_roles")
let existingTimezone = localStorage.getItem("timezone");
let existingGlobalSettings = localStorage.getItem("global_settings");

if (!existingTimezone) {
  localStorage.setItem("timezone", "America/Chicago")
  existingTimezone = "America/Chicago"
}

// console.log("local storage user", existingUser);
if (existingUser)
  try {
    existingUser = JSON.parse(existingUser);
    setUser = existingUser;
  } catch (e) { }

if (existingUserRoles) {
  try {
    existingUserRoles = JSON.parse(existingUserRoles)
    setUserRoles = existingUserRoles
  }
  catch (e) { }
}

if (existingRoles) {
  try {
    existingRoles = JSON.parse(existingRoles)
    setRoles = existingRoles
  }
  catch (e) { }
}

if (existingGlobalSettings) {
try{
  existingGlobalSettings = JSON.parse(existingGlobalSettings);
  setGlobalSettings = existingGlobalSettings;
}catch{}
}

export const mainStateObject = {
  addresses: {} as { [key: string]: IAddress },
  aspNetUser: {} as IAspNetUser,
  asp_net_users: {} as { [key: string]: IAspNetUser },
  clientOptions: {},
  contacts: {} as { [key: string]: IContact },
  error: {} as any,
  global_settings: setGlobalSettings as IGlobalSettings,
  jwt_token: '',
  loading: false,
  locations: {} as { [key: string]: ILocation },
  meta: {} as any,
  my_user: setUser as IAspNetUser,
  patients: {} as { [key: string]: IPatient },
  patient_plans: {} as { [key: string]: IPatientPlan },
  relationships: {} as { [key: string]: IRelationship },
  dependents: {} as { [key: string]: IDependent },
  dependent_patient_plans: {} as { [key: string]: IDependentPatientPlan },
  payment_datas: {} as { [key: string]: IPaymentData },
  resources: {} as { [key: string ]: IResource },
  states: {} as { [key: string]: IState },
  products: {} as { [key: string]: IProduct },
  success: {} as any,
  theme: {
    colors: getThemeColors(THEME_NAME.LIGHT),
    classes: {
      main: "light-theme",
    }
  } as ITheme,
  timezone: existingTimezone as string,
  sign_now_link: undefined,
  sign_up_step_forced: undefined
};

const resetState = _.cloneDeep(mainStateObject)

const initialState: IMainState = { ...mainStateObject };

const mainReducer = (
  state: any = initialState,
  action: IReduceAction
): IMainState => {

  if (action && (action.stateKey === "main" || !action.stateKey)) {
    if (action.normalized || action.removeKeys || action.overrides) {
      return autoReduce(action, state);
    }

    switch (action.type) {
      case ACTIONS.ERROR:
        if (isObject(action.payload)) {
          state.error = action.payload;
          return { ...state };
        }
        break;
      case ACTIONS.SUCCESS:
        if (isObject(action.payload)) {
          state.success = action.payload;
          return { ...state };
        }
        break;
      case ACTIONS.IS_LOADING:
        state.loading = true;
        return { ...state };
      case ACTIONS.NOT_LOADING:
        state.loading = false;
        return { ...state };
      case "GET_USER_NOTE_SUCCESS":
        state.user_notes = action.payload;
        break;
      case ACTIONS.SYNC_DATA_FROM_SOCKET:
        return {...state}
      case "LOGOUT_SUCCESS":
        localStorage.removeItem("jwt");
        localStorage.removeItem("my_user");
        localStorage.removeItem("user_roles");
        localStorage.removeItem("roles");
        state.jwt_token = "";
        // eslint-disable-next-line no-restricted-globals
        return { ...resetState }
      case "SET_CLIENT_OPTIONS_SUCCESS":
        state.clientOptions = {
          ...state.clientOptions,
          ...action.payload,
        };
        break;
      case "UPDATE_TIMEZONE":
        state.timezone = action.payload
        break;
      default:
        return state;
    }
  }
  return state;
};

export default mainReducer;
