import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tab, Box, useTheme } from '@mui/material';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CenteredContainer from '../../../../components/layout/CenteredContainer';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { SignupRegisterOverviewFormType, SignupRegisterOverview } from './SignupRegisterOverview';
import { SignupRegisterYourInfo, SignupRegisterYourInfoType } from './SignupRegisterYourInfo';
import { FormikProps } from 'formik';
import { SignupRegisterLocation, SignupRegisterLocationFormType } from './SignupRegisterLocation';
import { SignupRegisterPlanType, SignupRegisterPlanTypeFormType } from './SignupRegisterPlanType';
import { SignupRegisterPaymentInfo, SignupRegisterPaymentInfoType } from './SignupRegisterPaymentInfo';
import { SignupRegisterReview } from './SignupRegisterReview';
import { useSelector } from '../../../../store';

type RegisterStep = 'overview' | 'yourInfo' | 'location' | 'planType' | 'paymentInfo' | 'review';

const steps: RegisterStep[] = ['overview', 'yourInfo', 'location', 'planType', 'paymentInfo', 'review'];

const labels: Record<RegisterStep, string> = {
  overview: 'Overview',
  yourInfo: 'Your Info',
  location: 'Location',
  planType: 'Plan Type',
  paymentInfo: 'Payment Info',
  review: 'Review',
};

export const SignupRegister = () => {

  const [activeStep, setActiveStep] = useState<RegisterStep>('yourInfo');
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([]);
  const overview = useRef<FormikProps<SignupRegisterOverviewFormType>>(null);
  const yourInfo = useRef<FormikProps<SignupRegisterYourInfoType>>(null);
  const location = useRef<FormikProps<SignupRegisterLocationFormType>>(null);
  const planType = useRef<FormikProps<SignupRegisterPlanTypeFormType>>(null);
  const paymentInfo = useRef<FormikProps<SignupRegisterPaymentInfoType>>(null);
  const myPatientData = getMyPatientData();
  const theme = useTheme();

  const setCompleted = (step: RegisterStep) => {
    completedSteps[steps.indexOf(step)] = true;
    setCompletedSteps([ ...completedSteps ]);
  };

  const isCompleted = (step: RegisterStep) => completedSteps[steps.indexOf(step)];

  const nextStep = useMemo(() => steps[completedSteps.indexOf(false)], [completedSteps]);

  const resolveIcon = useCallback((step: RegisterStep) => (isCompleted(step) ? <CheckCircleOutlineOutlined /> : undefined), [completedSteps]);

  const isDisabled = useCallback((step: RegisterStep) => !isCompleted(step) && step !== nextStep, [nextStep]);

  const clientOptions = useSelector((state) => state.main.clientOptions);

  const handleChange = (_: React.SyntheticEvent, newValue: RegisterStep) => {
    // const ref = refs[steps.indexOf(activeStep)]?.current;
    // ref?.submitForm().then(() => {
    //  if (ref?.isValid) setActiveStep(newValue);
    //});
    setActiveStep(newValue);
  };

  useEffect(() => {
    setCompletedSteps([
      true,
      myPatientData?.info_complete,
      myPatientData?.location_complete,
      myPatientData?.plan_type_complete,
      myPatientData?.payment_data_complete,
      false,
    ]);
  }, [myPatientData?.info_complete, myPatientData?.location_complete, myPatientData?.plan_type_complete, myPatientData?.payment_data_complete]);

  useEffect(() => {
    if (clientOptions.signUpStep != null){
      setActiveStep(clientOptions.signUpStep);
    }
  }, 
  [clientOptions])

  useEffect(() => {
    if (nextStep) setActiveStep(nextStep);
  }, [nextStep]);

  return (
    <CenteredContainer>
      <Box
        style={{ backgroundColor: 'white' }}
        sx={{
          width: '95vw',
          [theme.breakpoints.up('md')]: {
            width: 1200,
          },
        }}
      >
        <TabContext value={activeStep}>
          <TabList onChange={handleChange} variant="fullWidth" centered>
            {steps.map((step) => (
              <Tab key={step} icon={resolveIcon(step)} iconPosition="end" label={labels[step]} disabled={isDisabled(step)} value={step} />
            ))}
          </TabList>
          <TabPanel value="overview">
            <SignupRegisterOverview
              ref={overview}
              onSuccess={() => {
                setCompleted('overview');
              }}
            />
          </TabPanel>
          <TabPanel value="yourInfo">
            <SignupRegisterYourInfo
              ref={yourInfo}
              onSuccess={() => {
                setCompleted('yourInfo');
              }}
            />
          </TabPanel>
          <TabPanel value="location">
            <SignupRegisterLocation
              ref={location}
              onSuccess={() => {
                setCompleted('location');
              }}
            />
          </TabPanel>
          <TabPanel value="planType">
            <SignupRegisterPlanType
              ref={planType}
              onSuccess={() => {
                setCompleted('paymentInfo');
              }}
            />
          </TabPanel>
          <TabPanel value="paymentInfo">
            <SignupRegisterPaymentInfo
              ref={paymentInfo}
              onSuccess={() => {
                setCompleted('review');
              }}
            />
          </TabPanel>
          <TabPanel value="review">
            <SignupRegisterReview
              onSuccess={() => {
                setCompleted('review');
              }}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </CenteredContainer>
  );
};
