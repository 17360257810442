import { Paper, Typography, Box, useTheme } from '@mui/material';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { useTranslation } from 'react-i18next';
import CenteredContainer from '../../../../components/layout/CenteredContainer';

export const SignupThankYouPage = () => {
  const { t } = useTranslation();

  // Fetch patient data
  const { patient_plan } = getMyPatientData();
  const theme = useTheme();
  return (

    <CenteredContainer>
      <Box
        style={{ backgroundColor: 'white' }}
        sx={{
          width: '95vw',
          [theme.breakpoints.up('md')]: {
            width: 1200,
          },
        }}
      >
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h4" gutterBottom>
            {t('Thank You')}
          </Typography>

          <Box mb={4}>
            <Typography variant="h6">{t('Your application has been successfully submitted.')}</Typography>
            <Typography variant="body1">{t('Your Patient Plan ID:')}</Typography>
            <Typography variant="h5" color="primary" fontWeight="bold">
              {patient_plan?.id || t('N/A')}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </CenteredContainer>


  );
};