import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { signIn } from "../../../store/actions/signupActions";
import { getMyUserData } from "../../../store/actions/userActions";
import { Box, Button, TextField } from "@mui/material";
import { showErrorMessage } from "../../../store/actions/genericAction";
import { useNavigate } from "react-router-dom";

export const SignupLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Required"),
  });

  const login = ({ email, password }: { email: string; password: string }) => {
    dispatch(
      signIn(
        {
          email: email,
          password: password,
        },
        () => {
          dispatch(
            getMyUserData({}, () => {
              navigate("/");
            })
          );
        },
        () => {
          dispatch(showErrorMessage("Email or password is incorrect"));
        },
        "",
        false,
        true
      )
    );
    // Add your login dispatch action here
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        login(values);
        actions.setSubmitting(false);
      }}
    >
      {({ handleChange, handleBlur, values, touched, errors }) => (
        <Form>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Field
              sx={{ width: "300px" }}
              as={TextField}
              name="email"
              label="Email"
              variant="outlined"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email ? errors.email : ""}
            />
            <Field
              sx={{ width: "300px" }}
              as={TextField}
              type="password"
              name="password"
              label="Password"
              variant="outlined"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={
                touched.password && errors.password ? errors.password : ""
              }
            />
            <Button
              variant={"outlined"}
              type="submit"
              style={{ padding: 10, marginTop: 20 }}
            >
              Login
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
